import styled from "@emotion/styled";

type ButtonProps = {
  width: string;
  height: string;
  bgColor: string;
  border: string;
};

export default styled.button`
  width: ${(props: ButtonProps) => props.width};
  height: ${(props: ButtonProps) => props.height};

  background: ${(props: ButtonProps) => props.bgColor};
  border-radius: 100px;
  border-color: ${(props: ButtonProps) => props.border};;
  outline: none;
  cursor:pointer;
  border-style: solid;
  border-width: 2px;


  
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
  color: white;
  vertical-align: middle;
`;

