import React, { Component } from 'react';
import Career from "../components/career";

export default class Careers extends Component {
    render() {
        return (
            <Career />
        )
    }
}
