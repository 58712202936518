import React, { Component } from "react";
import Layout from "./layout";
import styled from "@emotion/styled";
import Title from "./common/title";
import Button from "./common/button";
import Header from "./header";
import { Link } from "gatsby";

const valuepath = require("../images/value.svg") as string;
const tickpath = require("../images/tick.svg") as string;

/* Add the path to the team members image here */
const jay = require("../images/team/Jay.png") as string;
const prasad = require("../images/team/prasad.jpg") as string;
const chetan = require("../images/team/ChetanBandari.jpg") as string;
const vivekTej = require("../images/team/Vivek.jpg") as string;
const veeraChary = require("../images/team/Chary.jpg") as string;
const bhargav = require("../images/team/balu.jpeg") as string;
const preetham = require("../images/team/preetham.jpg") as string;
const sathwika = require("../images/team/Sathwika.jpg") as string;
const vikram = require("../images/team/Vikram.jpg") as string;
const madan = require("../images/team/madan.jpg") as string;
const murari = require("../images/team/murari.jpg") as string;
const saiTeja = require("../images/team/saiTeja.jpg") as string;
const shivaPrasad = require("../images/team/shivaPrasad.jpg") as string;
const pooja = require("../images/team/pooja_1.jpg") as string;
const swathiD = require("../images/team/swathi_d.jpg") as string;
const swathiK = require("../images/team/swathi_k.jpg") as string;
const kalpana = require("../images/team/kalpana.jpg") as string;
const kruthikSagar = require("../images/team/kruthikSagar.jpeg") as string;
const jyotiParit = require("../images/team/jyoti.jpg") as string;
const soujanyaM = require("../images/team/soujanya.jpg") as string;
const maruthiJ = require("../images/team/maruthi_1.jpg") as string;
const pallaviSangem = require("../images/team/pallavi.jpg") as string;
const pranusha = require("../images/team/pranusha.jpg") as string;
const kumarNayak = require("../images/team/kumarNayak.jpg") as string;
const shirishaK = require("../images/team/shirishaK.jpg") as string;
const akhilR = require("../images/team/akhilR.jpg") as string;
const mukundha = require("../images/team/mukundha.jpeg") as string;
const sripath = require("../images/team/sripath.jpg") as string;
const shivaUmmeda = require("../images/team/shiva_kumar.jpg") as string;
const nithin = require("../images/team/nithin.jpg") as string;
const saiSid = require("../images/team/saisid.jpg") as string;
const sowmya = require("../images/team/sowmya.jpg") as string;
const sandhya = require("../images/team/sandhya.jpeg") as string;
const manikanth = require("../images/team/manikanth.jpeg") as string;
const faisal_alim = require("../images/team/faisal_alim.jpg") as string;
const santhosh_kumar_p = require("../images/team/santhosh_kumar_p.jpg") as string;
const ravi_kiran_reddy = require("../images/team/ravi_kiran_reddy.jpg") as string;
const srija_m = require("../images/team/srija_m.jpg") as string;
const sai_kiran_jetti = require("../images/team/sai_kiran_jetti.jpg") as string;
const sai_sandeep = require("../images/team/sai_sandeep.jpg") as string;
const dileep_banoth = require("../images/team/dileep_banoth.jpg") as string;
const lokesh_kura = require("../images/team/lokesh_kura.jpg") as string;
const sowjanya_seela = require("../images/team/sowjanya_seela.jpg") as string;

/* 
Team Members array contains the data of all team members of tech.at.core
Add an entry of new team member with relevant data in the array if needed

 */
const teamMembers = [
  { name: "Jayasagar Jagirapu", role: "Founder", imagePath: jay },
  { name: "Prasad Jagirapu", role: "Managing Director", imagePath: prasad },
  {
    name: "Chetan Kumar Bandari",
    role: "Senior Software Engineer",
    imagePath: chetan,
  },
  {
    name: "Veera Chary Neerenamula",
    role: "Software Architect",
    imagePath: veeraChary,
  },
  { name: "Vivek Tej Aggu", role: "Software Engineer", imagePath: vivekTej },
  {
    name: "Bhargav Methuku",
    role: "Principal Software Engineer",
    imagePath: bhargav,
  },
  { name: "Sathwika Potholla", role: "QA Engineer", imagePath: sathwika },
  { name: "Vikram", role: "Software Engineer", imagePath: vikram },
  {
    name: "Madan Yellanki",
    role: "VP of Engineering",
    imagePath: madan,
  },
  { name: "Preetham", role: "Head of Product Innovation", imagePath: preetham },
  { name: "Shiva Prasad", role: "Software Engineer", imagePath: shivaPrasad },
  { name: "Murari Sabavath", role: "Software Engineer", imagePath: murari },
  { name: "Sai Teja Chunchu", role: "Software Engineer", imagePath: saiTeja },
  { name: "Pooja", role: "Software Engineer", imagePath: pooja },
  { name: "Swathi Devarapalli", role: "Scrum Master", imagePath: swathiD },
  { name: "Swathi Krishna", role: "Associate HR", imagePath: swathiK },
  { name: "Kalpana", role: "Software Engineer", imagePath: kalpana },
  { name: "Kruthik Sagar", role: "Security Engineer", imagePath: kruthikSagar },
  { name: "Jyoti Parit", role: "Software Engineer", imagePath: jyotiParit },
  { name: "Soujanya M", role: "Software Engineer", imagePath: soujanyaM },
  {
    name: "Maruthi Jittaveni",
    role: "Software Architect",
    imagePath: maruthiJ,
  },
  {
    name: "Pallavi Sangem",
    role: "Software Engineer",
    imagePath: pallaviSangem,
  },
  { name: "Pranusha", role: "Software Engineer", imagePath: pranusha },
  {
    name: "Kumar Nayak",
    role: "Senior Software Engineer",
    imagePath: kumarNayak,
  },
  {
    name: "Shirisha Kengarla",
    role: "Software Engineer",
    imagePath: shirishaK,
  },
  { name: "Akhil Raapally", role: "Software Engineer", imagePath: akhilR },
  { name: "Mukundha", role: "QA Engineer", imagePath: mukundha },
  { name: "Sripath T", role: "Associate UX Engineer", imagePath: sripath },
  {
    name: "Shiva Kumar",
    role: "Software Engineer",
    imagePath: shivaUmmeda,
  },
  { name: "Sai Siddhartha", role: "Software Engineer", imagePath: saiSid },
  { name: "Nithin S", role: "Software Engineer", imagePath: nithin },
  { name: "Manikanth", role: "Associate Admin", imagePath: manikanth },
  { name: "Sandhya Kanuganti", role: "QA Engineer", imagePath: sandhya },
  { name: "Sowmya Chowdary", role: "QA Engineer", imagePath: sowmya },
  { name: "Faisal Alim", role: "Software Engineer", imagePath: faisal_alim },
  { name: "Santhosh Kumar P", role: "Software Engineer", imagePath: santhosh_kumar_p },
  { name: "RaviKiran Reddy", role: "Software Engineer", imagePath: ravi_kiran_reddy },
  { name: "Srija M", role: "Software Engineer", imagePath: srija_m },
  { name: "Sai Kiran Jetti", role: "DevOps Engineer", imagePath: sai_kiran_jetti },
  { name: "Sai Sandeep", role: "Software Engineer", imagePath: sai_sandeep},
  { name: "Dileep Banoth", role: "QA Engineer", imagePath: dileep_banoth},
  { name: "Lokesh Kura", role: "Support Engineer/ Software Engineer", imagePath: lokesh_kura},
  { name: "Sowjanya Seela", role: "Software Engineer", imagePath: sowjanya_seela}
];

const BlogImg = require("../images/blog1.png") as string;
const CloudImg = require("../images/netlify.jpeg") as string;

const BgShade = styled.div`
  background: #000000;
  border: 1px solid #000000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (max-width: 860px) {
    width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
`;

const HeadTitle = styled(Title)`
  color: #ffffff;
  text-align: center;
  padding-top: 247px;
  padding-bottom: 117px;
  @media (max-width: 860px) {
    padding-top: 85px;
  }
`;

const MenuItem = styled(Link)`
  display: none;
  @media (max-width: 860px) {
    display: block;
    text-decoration: none;
  }
`;

const HomeButton = styled(Button)`
  display: none;
  @media (max-width: 860px) {
    position: relative;
    width: 286px;
    height: 48px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 131px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid #bbbbbb;
    box-sizing: border-box;
    border-radius: 100px;
  }
`;

const WhyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WhyTitle = styled(Title)`
  color: #000000;
  padding: 50px 0;

  @media (max-width: 600px) {
    margin-top: 10px;
    font-size: 30px;
  }
`;

const Desc = styled.p`
  max-width: 817px;
  margin-top: 100px;

  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #000000;

  @media (max-width: 817px) {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 0 10px;
    font-size: 18px;
    line-height: 1.5;
    display: flex;
  }
`;

const Break = styled.br``;

const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  margin-top: 80px;
  padding: 0 20px 70px;
`;

const ValueTitle = styled(Title)`
  color: #000000;
  text-align: center;
  margin: 50px;
  padding: 50px 0;

  @media (max-width: 600px) {
    font-size: 30px;
    margin: 0 auto;
  }
`;

const ValueImg = styled.img`
  width: 60px;
  height: 60px;
  margin: 30px;
  @media (max-width: 860px) {
    margin: 20px;
  }
`;

const ValueContentContainer = styled.div`
  display: flex;
  margin: 10%;
  margin-top: 30px;
  @media (max-width: 760px) {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0% 10%;
    justify-content: center;
    margin-top: 0 auto;
  }
`;

const ValueContentBlock = styled.div`
  flex: 1;
  margin: 10px;

  @media (max-width: 760px) {
    margin: 0 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ValueContentTitle = styled.div`
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 1.5;
  color: #363636;
  margin-top: 30px;
  @media (max-width: 860px) {
    margin-top: 10px;
    display: flex;
    text-align: center;
  }
`;

const ValueDesc = styled.p`
  margin-top: 15px;

  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  margin-top: 40px;

  @media (max-width: 760px) {
    font-size: 18px;
    margin-top: 10px;
    margin-left: 10px;
    text-align: center;
  }
`;

const TechContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 860px) {
    margin-bottom: 40px;
    text-align: center;
    display: flex;
    align-items: center;
  }
`;
const TechTitle = styled.div`
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 54px;
  line-height: 1.5;
  margin-top: 60px;
  color: #000000;
  text-align: center;
  padding: 50px 0;

  @media (max-width: 860px) {
    font-size: 30px;
    padding: 48px 0 35px 0;
    margin-top: 30px;
  }
`;

const TickContainer = styled.div`
  width: 80%;
  max-width: 1280px;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  padding-left: 90px;
  margin: 110px;
  @media (max-width: 860px) {
    margin: 10px;
    padding-left: 1px;
    align-items: center;
  }
`;

const TechBox = styled.div`
  width: 200px;
  height: 56px;
  display: flex;
  flex-direction: row;
  margin-right: 105px;
  margin-bottom: 40px;
  @media (max-width: 860px) {
    width: 300px;
    margin: 0 auto;
  }
`;

const TechTick = styled.img`
  width: 26px;
  height: 26px;
  display: flex;
`;

const TechDesc = styled.div`
  max-width: 226px;
  height: 56px;
  display: flex;
  margin-left: 15px;

  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 1.5;
  color: #363636;

  @media (max-width: 600px) {
    font-weight: 800;
    font-size: 21px;
    line-height: 1.2;
  }
`;

const TeamContainer = styled.div`
  width: 100%;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 860px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const TeamTitle = styled.div`
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 54px;
  line-height: 1.5;

  color: #000000;
  text-align: center;
  padding: 40px 0;
  margin-top: 50px;

  @media (max-width: 600px) {
    font-size: 30px;
  }
`;
const MemberContainer = styled.div`
  width: 80%;
  max-width: 1580px;
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  margin-top: 80px;
  padding-left: 25px;
  padding-bottom: 5%;
  gap: 40px;
  @media (max-width: 860px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-bottom: 20%;
  }
`;
const TeamBox = styled.div`
  width: 205px;
  height: 300px;
  @media (max-width: 860px) {
    width: 200px;
    dislay: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const TeamMember = styled.img`
  width: 100%;
  max-width: 200px;
  height: 200px;
  background: #ffffff;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  user-select: none;
  @media (max-width: 860px) {
    width: 358px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
`;

const MemberName = styled.div`
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 1.5;
  color: #363636;

  @media (max-width: 860px) {
    width: 200px;
    display: flex;
    flex-flow: row wrap;
  }
`;

const TechName = styled.div`
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.5;
  color: #000000;
`;

const BlogContainer = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  margin-bottom:30px;

  @media (max-width:480px){
    padding bottom:60px;
  }
`;

const BlogTitle = styled(Title)`
  color: #000000;
  text-align: center;
  padding: 40px 0;
  margin-top: 50px;

  @media (max-width: 480px) {
    padding: 10px 0;
    font-size: 30px;
  }
`;

const BlogBox = styled.div`
  width: 100%;
  max-width: 1580px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 80px;
  padding-bottom: 50px;
  gap: 50px;

  @media (max-width: 860px) {
    width: 80%;
    margin-top: 30x;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 20px;
  }
`;

const BoxOne = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  border: 1px solid #cfcfcf;
  box-sizing: border-box;

  @media (max-width: 860px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
`;

const BlogLink = styled(Link)`
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 54px;
  line-height: 1.5;
`;

const ImageBlog = styled.img`
  display:flex;
  height:350px;
  width: 100%;
  flex-direction:row;
  justify-content:center;
  align-items:center;

  @media(max-width:860px){
    width:100%;
    height:300px;
    display:flex;
    flex-direction:row;
    justify-content:center;
   
  
  
`;

const BlogName = styled(Title)`
  height:80px;
  font-size:24px;
  font-style:normal;
  color: #363636;
  line-height: 40px;
  font-style: normal;
  text-decoration: none;

  &:hover{
    text-decoration: underline;  
  }

  @media(max-width:860px){
    line-height: 26px;
    font-size:20px;
    display:flex;
    height: 58px;
    flex-direction:row;
    justify-content:center;
    padding:15px;
    text-decoration: none;

`;

const BlogHeader = styled.div`
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  color: #363636;
  line-height: 30px;
  margin-top: 20px;

  @media (max-width: 860px) {
    font-size: 18px;
    line-height: 24px;
    margin-top: 50px;
    padding: 0px 15px;
  }
`;

const HeaderDesi = styled.div`
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  color: #363636;
  line-height: 30px;

  @media (max-width: 860px) {
    font-size: 18px;
    line-height: 26px;
    padding: 0px 15px;
  }
`;

export default class career extends Component {
  render() {
    return (
      <Layout>
        <BgShade>
          <Header index={0} page={"careerPage"}></Header>
          <MenuItem to={"/"}>
            <HomeButton
              width="250px"
              height="50px"
              bgColor="none"
              border="#BBBBBB"
            >
              HOME
            </HomeButton>
          </MenuItem>
          <HeadTitle>Careers</HeadTitle>
        </BgShade>
        <WhyContainer>
          <WhyTitle id="title">Why tech.at.core ?</WhyTitle>
          <Desc>
            We are passionate Software specialist across various disciplines. We
            value quality of work and challenge continuous learning throughout
            the our journey.
            <Break></Break>
            <Break></Break>
            At tech.at.core, we believe human-first approach and you will get
            all the freedom and flexibility to work in your style and technology
            experiments.
            <Break></Break>
            <Break></Break>
            We help with the right mentorship program to have a right career
            path, we provide &nbsp;continuousone-to-one &nbsp;feedback sessions
            with your &nbsp; team and mentor.
            <Break></Break>
            <Break></Break>
            We believe, at tech.at.core you never get bored with the work and
            challenges will have.
          </Desc>
        </WhyContainer>
        <ValuesContainer>
          <ValueTitle>Our values</ValueTitle>
          <ValueContentContainer>
            <ValueContentBlock>
              <ValueImg src={valuepath}></ValueImg>
              <ValueContentTitle>Being Human</ValueContentTitle>
              <ValueDesc>
                We are human-first approach company and everything comes next.
              </ValueDesc>
            </ValueContentBlock>
            <ValueContentBlock>
              <ValueImg src={valuepath}></ValueImg>
              <ValueContentTitle>Unblock people</ValueContentTitle>
              <ValueDesc>
                Helping the team or people around is always comes as a top
                priority
              </ValueDesc>
            </ValueContentBlock>
            <ValueContentBlock>
              <ValueImg src={valuepath}></ValueImg>
              <ValueContentTitle>Lead by example mindset</ValueContentTitle>
              <ValueDesc>
                Inspire everyone and set the trend and show how to.
              </ValueDesc>
            </ValueContentBlock>
          </ValueContentContainer>
        </ValuesContainer>
        <TechContainer>
          <TechTitle>Life at tech.at.core</TechTitle>

          <TickContainer>
            <TechBox>
              <TechTick src={tickpath}></TechTick>
              <TechDesc>Being Human</TechDesc>
            </TechBox>

            <TechBox>
              <TechTick src={tickpath}></TechTick>
              <TechDesc>Unblock&nbsp;people</TechDesc>
            </TechBox>

            <TechBox>
              <TechTick src={tickpath}></TechTick>
              <TechDesc>Lead&nbsp;by&nbsp;example&nbsp;mindset</TechDesc>
            </TechBox>

            <TechBox>
              <TechTick src={tickpath}></TechTick>
              <TechDesc>Flexible&nbsp;working&nbsp;hours</TechDesc>
            </TechBox>

            <TechBox>
              <TechTick src={tickpath}></TechTick>
              <TechDesc>Flexible&nbsp;working&nbsp;machine</TechDesc>
            </TechBox>
          </TickContainer>
        </TechContainer>

        <TeamContainer>
          <TeamTitle>Our team</TeamTitle>

          <MemberContainer>
            {teamMembers.map((member) => (
              <TeamBox>
                <TeamMember src={member.imagePath}></TeamMember>
                <MemberName>{member.name}</MemberName>
                <TechName>{member.role}</TechName>
              </TeamBox>
            ))}
          </MemberContainer>
        </TeamContainer>

        <BlogContainer>
          <BlogTitle>Blog</BlogTitle>
          <BlogBox>
            <BoxOne>
              <BlogLink to={"/Build a Blog with Gatsby, Netlify and React"}>
                <ImageBlog src={CloudImg}></ImageBlog>
              </BlogLink>
              <BlogLink to={"/Build a Blog with Gatsby, Netlify and React"}>
                <BlogName>Build a Blog with Gatsby, Netlify and React</BlogName>
              </BlogLink>

              <BlogHeader>Chetan&nbsp;Kumar Bandari</BlogHeader>
              <HeaderDesi>DevOps Engineer</HeaderDesi>
            </BoxOne>

            <BoxOne>
              <BlogLink to={"/Deploy a Gatsby Site to Cloudflare"}>
                <ImageBlog src={BlogImg}></ImageBlog>
              </BlogLink>
              <BlogLink to={"/Deploy a Gatsby Site to Cloudflare"}>
                <BlogName>
                  How to Deploy a Gatsby Site to Cloudflare Workers With
                  Cloudbuild(CI/CD)
                </BlogName>
              </BlogLink>

              <BlogHeader>Chetan&nbsp;Kumar Bandari</BlogHeader>
              <HeaderDesi>DevOps Engineer</HeaderDesi>
            </BoxOne>
          </BlogBox>
        </BlogContainer>
      </Layout>
    );
  }
}
